import { Tabs } from 'antd';
import AddNewEvent from './new-event-form';
import AddFriend from './new-friend';
import AddNewPhoto from './new-photo-form';
import AddNewVideo from './new-video-form';

const items = [
  {
    key: '1',
    label: `Add New Event`,
    children: <AddNewEvent/>,
  },
  {
    key: '2',
    label: `Add Photo to database`,
    children: <AddNewPhoto/>,
  },
  {
    key: '3',
    label: `Add Video to database`,
    children: <AddNewVideo/>,
  },
  {
    key: '4',
    label: `Add a Friend to database`,
    children: <AddFriend/>,
  },
];
const TabsOption = () => <Tabs defaultActiveKey="1" items={items}  />;
export default TabsOption;