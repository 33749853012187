import React, { Component } from 'react';
import { Input, Button, Row, Col, message } from 'antd';
import axios from 'axios';

import { BACK } from './constant'

class AddNewVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            id_video: ''
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log('Title:', this.state.title);
        console.log('id_video:', this.state.id_video);
        axios.post(`${BACK}/api/videos`, { title: this.state.title, id_video: this.state.id_video })
            .then(response => {
                console.log(response);
                message.success("You added a new video", [100])
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <Row type="flex" justify="space-around" align="middle">
                <Col align="middle">
                    <br />
                        <form onSubmit={this.handleSubmit}>
                            <Input placeholder="Video Title" name="title" value={this.state.title} onChange={this.handleInputChange} />
                            <br /><br />
                            <Input placeholder="ID Video" name="id_video" value={this.state.id_video} onChange={this.handleInputChange} />
                            <br /><br />
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </form>
                </Col>
            </Row>
        );
    }
}

export default AddNewVideo;
