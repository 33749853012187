
import { Card } from 'antd'
import React, { Component } from 'react'

import { Layout, Menu } from 'antd';


import TabsOption from './tabs-option'
import UpdateFriends from './update-friend';
const { Header, Content, Footer } = Layout;


class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLogout() {
        this.props.onLogout()
    }


    render() {
        return (
            <>
                <Layout style={{ minHeight: "100vh" }}>
                    <Header>
                        <div  />
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                            <Menu.Item key="1">Dashboard Liviu Stoica DJ</Menu.Item>
                            <Menu.Item key="2" onClick={this.handleLogout}>Log out</Menu.Item>
                        </Menu>
                    </Header>
                    <Content  style={{ padding: ' 60px' }}>
                        <div >
                            <Card >
                                <TabsOption/>
                            </Card>
                        </div>
                        <div>
                            <UpdateFriends/>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>©2023 Created by Robotics Media</Footer>
                </Layout>

            </>
        )
    }
}

export default Dashboard

/*      YOUTUBE IFRAME

                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jA6U2mLck4Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
*/
