import React, { Component } from 'react';
import { Input, Button, Row, Col, message } from 'antd';
import axios from 'axios';

import { BACK } from './constant'

class AddNewPhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event_name: '',
            id_photo: ''
        };
    }

    handleInputChange(field) {
        return function (event) {
          this.setState({ [field]: event.target.value })
        }
      }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log('Title:', this.state.event_name);
        console.log('id_video:', this.state.id_photo);
        axios.post(`${BACK}/api/photos`, { event_name: this.state.event_name, id_photo: this.state.id_photo })
            .then(response => {
                console.log(response);
                message.success("You added a new photo", [100])
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <Row type="flex" justify="center" align="middle">
                <Col align="middle">
                    <br />
                    <form onSubmit={this.handleSubmit}>
                        <Input placeholder="Event Name" name="title" value={this.state.event_name} onChange={this.handleInputChange('event_name').bind(this)} />
                        <br />
                        <br />
                        <Input placeholder="ID Photo" name="id_photo" value={this.state.id_photo} onChange={this.handleInputChange('id_photo').bind(this)} />
                        <br />
                        <br />
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </form>
                </Col>
            </Row>
        );
    }
}

export default AddNewPhoto;

