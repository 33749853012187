import React, { Component } from "react";
import './App.css'; 
import 'antd/dist/reset.css';
import Dashboard from "./assets/Dashboard";
import LoginPage from './assets/Login';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogin() {
    this.setState({ isLoggedIn: true });
  }

  handleLogout() {
    this.setState({ isLoggedIn: false });
  }

  render() {
    const { isLoggedIn } = this.state;

    return (
      <div>
        {isLoggedIn ? (
          <Dashboard onLogout={this.handleLogout}/>
        ) : (
          <LoginPage onLogin={this.handleLogin} />
        )}
      </div>
    );
  }
}

export default App;