import React, { Component } from 'react';
import { Input, Button, Row, Col, message } from 'antd';
import axios from 'axios';
import { BACK } from './constant';

class AddFriend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            logo: '',
            display: '',
            site: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (field) => (event) => {
        this.setState({ [field]: event.target.value });
        console.log(event.target.value);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${BACK}/api/friends`, { name: this.state.name, logo: this.state.logo, display: this.state.display, site: this.state.site })
            .then(response => {
                console.log(response);
                message.success("You added a new friend", [10])
                this.resetForm()
            })
            .catch(error => {
                console.log(error);
            });
    }
    resetForm = () => {
        this.state({
            name: '',
            logo: '',
            display: '',
            site: ''
        })
    }

    render() {
        const options = [
            { value: 'YES', label: 'YES' },
            { value: 'NO', label: 'NO' }
        ]
        return (
            <Row type="flex" justify="center" align="middle">
                <Col align="middle">
                    <br />
                    <form onSubmit={this.handleSubmit}>
                        <Input placeholder="Name" name="name" label="Name" value={this.state.name} onChange={this.handleInputChange('name')} />
                        <br />
                        <br />
                        <Input placeholder="Logo" name="logo" label="Logo" value={this.state.logo} onChange={this.handleInputChange('logo')} />
                        <br />
                        <br />
                        <select  style={{ minWidth: 180, textAlign: "left" }} value={this.state.display} onChange={this.handleInputChange('display')} >
                            <option value="">Display</option>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <br />
                        <br />
                        <Input placeholder="Site" name="site" label="Site" value={this.state.site} onChange={this.handleInputChange('site')} />
                        <br />
                        <br />
                        <Button type="primary" htmlType="submit"> Add Friend </Button>
                    </form>
                </Col>
            </Row>

        );
    }
}

export default AddFriend;
