import React, { Component } from "react";
import axios from "axios";

import { Form, Input, Button, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { Col, Row } from "antd"
import { BACK } from "./constant";



class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(values) {
    this.setState({ loading: true });

    const { username, password } = values;

    axios.post(`${BACK}/api/auth`, { username, password })
      .then(response => {
        console.log(response.data);
        message.success('Logged in successfully');
        this.props.onLogin();
      })
      .catch(error => {
        console.log(error);
        message.error('Invalid username or password');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }


  render() {


    return (
      <div className="center">
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col span={24}  align="middle" >

            <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ maxWidth: 800 }} initialValues={{ remember: false }} onFinish={this.handleSubmit} autoComplete="on">
              <Form.Item name="username" rules={[{ required: true, message: 'Please input username!' }]}>
                <Input size="large" placeholder="Username" value={this.state.username} onChange={(event) => this.setState({ username: event.target.value })} prefix={<UserAddOutlined />} />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input password!' }]}>
                <Input.Password size="large" placeholder="Password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit" >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginPage;
