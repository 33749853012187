// 'http://localhost:4000/api/upcoming_events/upload'

import React, { Component } from 'react';
import {  Button, Input, DatePicker, message } from 'antd';
import {  Col, Row } from "antd"
import axios from 'axios';
import moment from 'moment';
import { BACK } from './constant';

class AddNewEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "",
            title: "",
            eventDate: "",
            location: "",
            site: "",
            type: "",
            isUploaded: false
        };
    }


    
    handleInputChange(field) {
        return function (event) {
            this.setState({ [field]: event.target.value })
            console.log(event.target.value);
        }
    }
    handleDateChange = (date, dateString) => {

        const formattedDate = moment(dateString).format('YYYY-MM-DD');
        this.setState({
            eventDate: formattedDate
        });
        console.log(formattedDate);
    }

    handleFileUpload = event => {
        event.preventDefault()
        axios.post(`${BACK}/api/event`, {
            title: this.state.title,
            date: this.state.eventDate,
            location: this.state.location,
            site: this.state.site,
            type: this.state.type,
            image: this.state.image,
        })
            .then((response) => {
                message.success("You added a new event", [100])
                // this.resetForm()
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    }
    resetForm = () => {
        this.state({
            image: "",
            title: "",
            eventDate: "",
            location: "",
            link: "",
            type:""
        })
    }

    render() {
        const options = [
            { value: '16/9', label: '16/9' },
            { value: '9/16', label: '9/16' }
        ]
        return (
            <Row type="flex" justify="center" align="middle" gutter={[16, 16]} >
                <Col align="middle" >
                    
                        <Input placeholder="Event Name" name="title" value={this.state.title} onChange={this.handleInputChange('title').bind(this)} />
                        <br/>
                        <br/>
                        <Input placeholder="Event Location" name="location" value={this.state.location} onChange={this.handleInputChange('location').bind(this)} />
                        <br/>
                        <br/>
                        <Input placeholder="Facebook Event Site" name="site" value={this.state.site} onChange={this.handleInputChange('site').bind(this)} />
                        <br/>
                        <br/>
                        <DatePicker style={{width:'100%'}} placeholder="Event Date" name="eventDate" size='large' onChange={this.handleDateChange} />
                        <br/>
                        <br/>
                        <select  style={{ minWidth: 180, textAlign: "left" }} value={this.state.type} onChange={this.handleInputChange('type').bind(this)} >
                            <option value="">Poster type</option>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <br/>
                        <br/>
                        <Input placeholder="Event Poster ID" name="image" value={this.state.image} onChange={this.handleInputChange('image').bind(this)} />
                        <br/>
                        <br/>
                        <Button type="primary" size='large' onClick={this.handleFileUpload}>Upload New Event</Button>
                </Col>
            </Row>
        );
    }
}

export default AddNewEvent;
