/*
import React, { Component } from 'react';
import axios from 'axios';
import { Table, Form, Input, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useState } from "react";
*/
import React from 'react';
import { Table, Input, Button, Popconfirm, message, Select, Divider } from 'antd';
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { BACK } from './constant'
import { SaveOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';

const {Option} = Select

// http://localhost:4000/api/friends

class UpdateFriends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          editingKey: '',
          editValue: {},
        };
      }
    
      componentDidMount() {
        this.fetchData();
      }
    
      fetchData = async () => {
        try {
          const response = await axios.get(`${BACK}/api/friends`);
          this.setState({ data: response.data });
        } catch (error) {
          console.log(error);
        }
      };
    
      editRow = (record) => { 
        const { editingKey } = this.state;
        if (editingKey !== '') return;
    
        this.setState({ editingKey: record.key, editValue: record });
      };
    
      
      saveRow = async () => {
        const { editingKey, editValue } = this.state;
    
        try {
          await axios.put(`${BACK}/api/friends/${editingKey}`, editValue);
          const { data } = this.state;
          const newData = data.map((item) => {
            if (item.key === editingKey) {
              return { ...item, ...editValue };
            }
            return item;
          });
          this.setState({ data: newData, editingKey: '', editValue: {} });
          this.handleRefresh();
        } catch (error) {
          console.log(error);
        }
      };

      deleteRow = async (key) => {
        try {
          await axios.delete(`${BACK}/api/friends/${key}`);
          message.success('Row deleted successfully!');
          this.fetchData();
        } catch (error) {
          console.error(error);
          message.error('Failed to delete row.');
        }
      };
    
      handleEditChange = (e, field) => {
        const { editValue } = this.state;
        this.setState({ editValue: { ...editValue, [field]: e.target.value } });
      };
    
      handleRefresh = () => {
        this.fetchData();
      };

      renderEditableCell = (record, dataIndex) => {
        const { editingKey, editValue } = this.state;
        const editable = editingKey === record.key;
    
        if (dataIndex === 'display' && editable) {
            return (
                <Select value={editValue[dataIndex]} onChange={(value) => this.handleEditChange({ target: { value } }, dataIndex)}>
                <Option value="YES">YES</Option>
                <Option value="NO">NO</Option>
              </Select>
            );
          }
        
          
        return editable ? (
          <Input value={editValue[dataIndex]} onChange={(e) => this.handleEditChange(e, dataIndex)} />
        ) : (
          record[dataIndex]
        );
      };
    
      render() {
        const { data, editingKey } = this.state;
    
        const columns = [
            {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            editable: false,
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            render: (_, record) => this.renderEditableCell(record, 'name'),
          },
          {
            title: 'logo',
            dataIndex: 'logo',
            key: 'logo',
            editable: true,
            render: (_, record) => this.renderEditableCell(record, 'logo'),
          },
          {
            title: 'display',
            dataIndex: 'display',
            key: 'display',
            editable: true,
            render: (_, record) => this.renderEditableCell(record, 'display'),
            ...(editingKey !== '' && {
              render: (_, record) => this.renderEditableCell(record, 'display'),
            }),
          },
          {
            title: 'site',
            dataIndex: 'site',
            key: 'site',
            editable: true,
            render: (_, record) => this.renderEditableCell(record, 'site'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
              const editable = editingKey === record.key;
              return editable ? (
                <span>
                  <Button type="link" onClick={this.saveRow}>
                    <SaveOutlined/> Save
                  </Button>
                  <Button type="link" onClick={() => this.setState({ editingKey: '', editValue: {} })}>
                    <CloseOutlined/> Cancel
                  </Button>
                </span>
              ) : (
                <>
                <Button type="link" disabled={editingKey !== ''} onClick={() => this.editRow(record)}>
                    <EditOutlined disabled={editingKey !== ''} onClick={() => this.editRow(record)}/>
                </Button>
                <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteRow(record.id)}>
                    <DeleteOutlined style={{color: "red"}}  />
                </Popconfirm>
                </>
              );
            }, 
          },
        ];
    
        const tableData = data.map((item) => ({ ...item, key: item.id }));
    
        return (
            <div>
                <Divider/>
                <Divider> Edit the Friends Section on the website </Divider>
            <Button type="primary" onClick={()=> this.handleRefresh()} >Reload</Button>
            <Table
              bordered
              dataSource={tableData}
              columns={columns}
              rowClassName={(record) => (editingKey === record.key ? 'editable-row' : '')}
              pagination={{ pageSize: 5 }}
              size="middle"
            />
            </div>
        );
      }
}


export default UpdateFriends;

